<template>
  <div class="page__wrapper">
    <ag-grid
      ref="projectMaterialGrid"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            v-permission="'share_projectMaterial:importNew'"
            type="primary"
            @click="isShowImportDialog=true"
          >
            {{ $t('share_projectMaterial.uploadNewFile') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <import-dialog :show-flag.sync="isShowImportDialog" />
  </div>
</template>

<script>
import Vue from 'vue'
import importDialog from './importDialog'

const BASEURL = {
  list: '/jlr/tt/projectInformation/list',
  download: '/system/attachment/download',
  update: '/jlr/tt/projectInformation/update',
  save: '/jlr/tt/projectInformation/save',
  delete: '/jlr/tt/projectInformation/delete'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleExportClick', 'handleDeleteClick', 'handlePublishClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.download')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-download" v-permission="'share_projectMaterial:export'"
          @click="handleExportClick(params.data)"/>
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('share_projectMaterial.publish')">
        <el-button type="text" style="font-size:14px;" :disabled="params.data.bizStatus==='Y'" v-permission="'share_projectMaterial:publish'"
          icon="el-icon-s-promotion" @click="handlePublishClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('operation.delete')">
        <el-button type="text" style="font-size:14px;" icon="el-icon-delete"  v-permission="'share_projectMaterial:delete'"
          @click="handleDeleteClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'ProjectMaterial',
  components: { importDialog },
  data () {
    return {
      searchModel: {},
      tableData: [],
      gridOptions: {},
      currentData: {},
      // isShowUploader: false,
      isShowImportDialog: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleDeleteClick: this.handleDeleteClick,
      handleExportClick: this.handleExportClick,
      handlePublishClick: this.handlePublishClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'fileName',
          type: 'Input',
          label: this.$t('share_projectMaterial.name'),
          component: { clearable: true }
        },
        { slotName: 'tableOperation', col: { xs: 18, sm: 18, md: 18 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('share_projectMaterial.name'),
          field: 'attachment.fileName',
          minWidth: 280
        },
        {
          headerName: this.$t('share_projectMaterial.date'),
          field: 'updateDate',
          minWidth: 180
        },
        {
          headerName: this.$t('share_projectMaterial.status'),
          field: 'bizStatus',
          valueFormatter: params => this.$getDictLabel({ type: 'yes_no', value: params.value }),
          minWidth: 180
        },
        {
          headerName: this.$t('system_office.region'),
          field: 'region',
          valueFormatter: params => this.$getDictLabel({ type: 'sys_office_region', value: params.value }),
          minWidth: 180
        },
        {
          headerName: this.$t('system_office.salesArea'),
          field: 'salesArea',
          valueFormatter: params => this.$getDictLabel({ type: 'sales_area', value: params.value }),
          minWidth: 180
        },
        {
          headerName: this.$t('system_office.afterSalesArea'),
          field: 'afterSalesArea',
          valueFormatter: params => this.$getDictLabel({ type: 'after_sales_area', value: params.value }),
          minWidth: 180
        },
        {
          headerName: this.$t('system_user.companyName'),
          field: 'dealerNames',
          minWidth: 180
        },
        {
          headerName: this.$t('system_user.roleList'),
          field: 'roleNames',
          minWidth: 180
        },
        {
          headerName: this.$t('share_projectMaterial.createBy'),
          field: 'updateBy.name',
          minWidth: 180
        },
        {
          headerName: this.$t('field.operation'),
          suppressSizeToFit: true,
          width: 100,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      // const params = { filter: [{ left: 'attachment.fileName', operate: 'like', right: this.searchModel.fileName || '' }] }
      const params = { attachment: { fileName: this.searchModel.fileName || '' } }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleExportClick (row) {
      this.$utils.fileSaveAs(BASEURL.download, { id: row.attachment.id })
    },
    // handleImportClick (row) {
    //   this.currentData = row
    //   this.isShowUploader = true
    // },
    handlePublishClick (row) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      let submitData = this.$_.clone(row)
      submitData.bizStatus = 'Y'
      this.$axios
        .post(BASEURL.update, submitData)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('share_projectMaterial.generateSuccess') })
          this.handleDataSearch()
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    // handleUploadSuccess (response, file, fileList) {
    //   const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
    //   let submitData = this.$_.clone(this.currentData)
    //   submitData.bizStatus = 'N'
    //   submitData.attachment = { id: response.data.id }
    //   this.$axios
    //     .post(BASEURL.save, submitData)
    //     .then(resp => {
    //       this.$message({ type: 'success', message: this.$t('tip.uploadSuccess') })
    //       this.handleDataSearch()
    //     })
    //     .finally(() => {
    //       loadingFlag.close()
    //       this.isShowUploader = false
    //       this.handleDataSearch()
    //     })
    // },
    handleDeleteClick (row) {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            this.$axios
              .post(BASEURL.delete, { id: row.id })
              .then(resp => {
                this.$message({ type: 'success', message: this.$t('tip.deleteSuccess') })
                done()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
              })
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
