<template>
  <el-dialog
    top="30px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('share_projectMaterial.uploadNewFile') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="false"
      />
      <el-button
        type="primary"
        style="margin-left:80px;width:100px;"
        @click="uploadClickHandler"
      >
        {{ $t('operation.uploadFile') }}
      </el-button>
    </div>
    <div slot="footer">
      <el-button @click="currentShowFlag=false">
        {{ $t('operation.cancel') }}
      </el-button>
    </div>
    <simple-upload
      ref="uploader"
      url="/system/attachment/upload"
      :show-flag.sync="isShowUploader"
      @before-upload="beforeUpload"
      @on-success="handleUploadSuccess"
    />
  </el-dialog>
</template>
<script>
const BASEURL = {
  role: '/system/role/list',
  office: '/system/office/list',
  save: '/jlr/tt/projectInformation/save'
}
export default {
  props: {
    showFlag: { type: Boolean, default: false }
  },
  data () {
    return {
      formModel: { roleId: [], dealerId: {} },
      isShowUploader: false,
      uploadParams: {},
      sysRoleList: [],
      companyList: [],
      companyLoadingFlag: false
    }
  },
  computed: {
    currentShowFlag: {
      get () {
        return this.showFlag
      },
      set (val) {
        this.$emit('update:showFlag', val)
      }
    },
    formField () {
      return [
        {
          prop: 'region',
          type: 'Select',
          label: this.$t('system_office.region'),
          component: {
            optionList: this.$getDictList('sys_office_region'),
            valueKey: 'id',
            clearable: true,
            disabled: this.formModel.dealerId.level === 3
          }
        },
        {
          prop: 'salesArea',
          type: 'Select',
          label: this.$t('system_office.salesArea'),
          component: {
            optionList: this.$getDictList('sales_area'),
            valueKey: 'id',
            clearable: true,
            disabled: this.formModel.dealerId.level === 3
          }
        },
        {
          prop: 'afterSalesArea',
          type: 'Select',
          label: this.$t('system_office.afterSalesArea'),
          component: {
            optionList: this.$getDictList('after_sales_area'),
            valueKey: 'id',
            clearable: true,
            disabled: this.formModel.dealerId.level === 3
          }
        },
        {
          prop: 'dealerId',
          label: this.$t('system_user.companyName'),
          type: 'SearchableInput',
          component: {
            remoteMethod: this.getCompanyList,
            loading: this.companyLoadingFlag,
            optionList: this.companyList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getCompanyList()
            },
            change: (val) => {
              if (val.level === 3) {
                this.$set(this.formModel, 'region', val.region)
                this.$set(this.formModel, 'salesArea', val.salesArea)
                this.$set(this.formModel, 'afterSalesArea', val.afterSalesArea)
              } else {
                this.$set(this.formModel, 'region', '')
                this.$set(this.formModel, 'salesArea', '')
                this.$set(this.formModel, 'afterSalesArea', '')
              }
            }
          }
          // rules: {
          //   type: 'object',
          //   trigger: 'change',
          //   message: this.$t('validate.notSelect'),
          //   // required: true,
          //   fields: {
          //     id: {
          //       type: 'string',
          //       message: this.$t('validate.notSelect'),
          //       required: true
          //     }
          //   }
          // }
        },
        {
          prop: 'roleId',
          label: this.$t('system_user.roleList'),
          type: 'Select',
          component: {
            optionList: this.sysRoleList,
            multiple: true,
            valueKey: 'id',
            clearable: true
          }
          // rules: {
          //   type: 'array',
          //   trigger: 'change',
          //   message: this.$t('validate.notSelect'),
          //   required: true
          // }
        }
      ]
    }
  },
  inject: ['handleDataSearch'],
  methods: {
    handleDialogOpen () {
      this.getSysRoleList()
    },
    uploadClickHandler () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        this.isShowUploader = status
      })
    },
    beforeUpload () {
      this.uploadParams.region = this.formModel.region
      this.uploadParams.salesArea = this.formModel.salesArea
      this.uploadParams.afterSalesArea = this.formModel.afterSalesArea
      this.uploadParams.dealerId = this.formModel.dealerId ? this.formModel.dealerId.id : ''
      this.uploadParams.dealerNames = this.formModel.dealerId ? this.formModel.dealerId.name : ''
      if (this.formModel.roleId) {
        const ids = this.formModel.roleId.map(ele => { return ele.id })
        const names = this.formModel.roleId.map(ele => { return ele.name })
        this.uploadParams.roleId = ids.join(',')
        this.uploadParams.roleNames = names.join(',')
      } else {
        this.uploadParams.roleId = ''
        this.uploadParams.roleNames = ''
      }
    },
    handleUploadSuccess (response, file, fileList) {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      let submitData = this.$_.clone(this.uploadParams)
      submitData.attachment = { id: response.data.id }
      this.$axios
        .post(BASEURL.save, submitData)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.uploadSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
          this.isShowUploader = false
          this.currentShowFlag = false
          this.handleDataSearch()
        })
    },
    getSysRoleList () {
      this.$axios.post(BASEURL.role, {}).then(resp => {
        const respData = resp.data
        this.sysRoleList = respData.list.map(item => {
          return { key: item.id, value: item, label: item.name }
        })
      })
    },
    getCompanyList (name) {
      this.companyLoadingFlag = true
      const filter = [
        { left: 'parentIds', operate: 'like', right: 'root_id' }
      ]
      if (name) { filter.push({ left: 'name', operate: 'like', right: name }) }
      this.$axios
        .post(BASEURL.office, { pageSize: 10, filter: filter })
        .then(resp => {
          const respData = resp.data
          this.companyList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.companyLoadingFlag = false
        })
    },
    handleDialogClosed () {
      this.formModel = { roleId: [], dealerId: {} }
      this.$refs.form.resetFields()
    }
  }
}
</script>
